import React from 'react';

import ReferralImage from 'images/referral.png';

export function Referral() {
  return(
    <img
      alt="Referral"
      className="Referral"
      src={ReferralImage}
    />
  )
};

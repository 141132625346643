import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from 'lib/font_awesome/solid';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@user-interviews/ui-design-system';

import { TrackedAnchor } from 'common/analytics';

import { trackingEvents } from 'lib/analytics';
import { activityNames } from 'lib/generated_constants/integrations';
import {
  formatDateAndTimeWithAbbrevMonth,
  formatDurationFromSeconds,
} from 'lib/formatters/date_time';
import { toSentenceCase } from 'lib/formatters/strings';
import * as propTypes from 'lib/prop_types';

import * as styles from './activity_groups.module.css';

function ActivityGroups({ data = [] }) {
  if (!data.length) return null;

  const activityName = activityNames[data[0].provider] || activityNames.default;

  return (
    <Table className={styles.container}>
      <TableHead>
        <TableRow>
          <TableCell header>
            Link to {activityName}
          </TableCell>
          <TableCell header>
            Start time
          </TableCell>
          <TableCell header>
            Duration
          </TableCell>
          <TableCell header>
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(
          ({
            duration,
            id,
            provider,
            startTime,
            status,
            url,
          }) => (
            <TableRow key={id}>
              <TableCell>
                {url ?
                  (
                    <TrackedAnchor
                      event={trackingEvents.SESSION_ACTIVITY_LINK_CLICKED}
                      eventData={{ provider }}
                      href={url}
                      target="_blank"
                    >
                      {toSentenceCase(activityName)}
                      <FontAwesomeIcon className="icon-right" icon={faExternalLinkAlt} />
                    </TrackedAnchor>
                  ) :
                  'No link found'}
              </TableCell>
              <TableCell>
                {startTime &&
                  (
                    formatDateAndTimeWithAbbrevMonth(startTime)
                  )}
              </TableCell>
              <TableCell>
                {
                  duration ?
                    (formatDurationFromSeconds(Number(duration), true)) :
                    'Not available'
                }
              </TableCell>
              <TableCell>
                {toSentenceCase(status)}
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  );
}

ActivityGroups.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      // TODO: look into backend endpoint standardizing duration to be a number or string
      duration: propTypes.oneOfType([propTypes.string, propTypes.number]),
      id: propTypes.string.isRequired,
      provider: propTypes.string.isRequired,
      startTime: propTypes.string,
      status: propTypes.string.isRequired,
      url: propTypes.string,
    }),
  ),
};

export default ActivityGroups;

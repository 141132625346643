import React from 'react';
import * as propTypes from 'lib/prop_types';

import UnmoderatedTaskImage from 'images/unmoderated_task.png';

function UnmoderatedTask({ className = '' }) {
  return (
    <img
      alt="Unmoderated task"
      className={`UnmderatedTask ${className}`}
      src={UnmoderatedTaskImage}
    />
  )
}

UnmoderatedTask.propTypes = {
  className: propTypes.string,
};

export default UnmoderatedTask;

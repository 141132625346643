import React from 'react';

import { usePageTitle } from 'hooks/use_page_title';

type TitledPageProps = {
  children?: React.ReactNode,
  title?: string,
}

/**
 * This will append ' | User Interviews' to all HTML titles and update on load.
 * PLEASE DO NOT SET `DOCUMENT.TITLE` OUTSIDE OF THIS IF POSSIBLE.
 * If you need to change HTML tilte, refer to `usePageTitle` hook and `triggerUsePageTitleUpdate` function.
 */
export function TitledPage({
  children,
  title,
}: TitledPageProps) {
  usePageTitle(title);

  return <>{children}</>;
}

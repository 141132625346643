import React from 'react';
import * as propTypes from 'lib/prop_types';

import FocusGroupImage from 'images/focus_group.png';

function FocusGroup({ className = '' }) {
  return (
    <img
      alt="Focus group"
      className={`FocusGroup ${className}`}
      src={FocusGroupImage}
    />
  )
}

FocusGroup.propTypes = {
  className: propTypes.string,
};

export default FocusGroup;

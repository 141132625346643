import React from 'react';

import InPersonStudyImage from 'images/in_person_study.png';

export function InPerson() {
  return(
    <img
      alt="In person"
      className="InPerson"
      src={InPersonStudyImage}
    />
  )
};

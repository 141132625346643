import React from 'react';
import * as propTypes from 'lib/prop_types';

import PhoneImage from 'images/phone_study.png';

function Phone({ className = '' }) {
  return (
    <img
      alt="Phone studies"
      className={`Phone ${className}`}
      src={PhoneImage}
    />
  )
}

Phone.propTypes = {
  className: propTypes.string,
};

export default Phone;
